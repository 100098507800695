@font-face {
  font-family: 'Ortica-Light';
  src: url('./assets/Ortica-Light.otf');
  font-weight: light;
  font-style: normal;
}


body {
  width: 100vw;
  height: 100vh;
  /* background: linear-gradient(180deg, #ff008c 0%, rgb(211, 9, 225) 100%); */
  background:#0B0B0B;
  color: #F5F3E9;
  overflow: hidden;
  padding: 0;
  margin: 0;
  /* display: flex;
  justify-: center;
  align-items: center; */
  font-family: 'Ortica-Light', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2{
  font-size: 72px;
  font-weight:lighter;
  line-height:56px;
  margin-bottom:24px;
}

h4{
  padding-top:0px;
  margin-top:0px;
  font-size: 16px;
  font-weight:lighter;
}

#root {
  height: 100%;
  overflow-y:scroll;
}